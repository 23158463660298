<script setup> 
    /* IMPORTS */
    import { getOrCreateProcedure } from 'o365-modules';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { ref, onMounted, watch, isProxy, toRaw } from 'vue';
    import { OPersonsLookup} from 'o365-system-lookups';
    import { OOrgUnitsLookup as OOrgunitsLookup} from 'o365-system-lookups';
    import { ONumberEditor } from 'o365-ui-components';
    import { ODatePicker } from 'o365-ui-components';
    import ModalExpertise from "resource.vue.components.ExpertiseModal.vue";
    import { context } from 'o365-modules';
    import { confirm } from 'o365-vue-services';


    /* VARIABLES */
    const props = defineProps({ 
                                RefreshDataObject: Object
                            });
    const vProcData = ref({ OrgUnit_ID: context.id,
                            ResourceOrgUnit_ID: null,
                            FunctionRole_ID: null,
                            Responsible_ID: null,
                            FromDate: null,
                            ToDate: null,
                            FTE: 1,
                            NumberOfAssignments: 1,
                            RateCategory_ID: null,
                            WBS_ID: null,
                            Description: null,
                            ExpertiseIDs: null,
                            ExpertiseWithLevelIDs: null,
                            OrgUnit: context.name,
                            ResourceOrgUnit: null,
                            FunctionRole: null,
                            Responsible: null,
                            RateCategory: null,
                            WBS: null,
                            Expertise: null,
                            ExpertiseWithLevel: null
                        });
    const vWBSFilter = ref(`IsWorkpackage=1 AND Canceled=0 AND IsClosed=0 AND ExcludeFromResourceMgmt=0 AND OrgUnit_ID = ${vProcData.value.OrgUnit_ID}`);
    const vRateCategoryFilter = ref(`OrgUnit_ID = ${vProcData.value.OrgUnit_ID}`);
    const vExpertise = ref([]);
    const vExpertiseLevels = ref([]);
    const isConfirmationModalShown = ref(false); 
    const newPositionModal = ref(null);
    window["vExpertise"] = vExpertise;
    window["vExpertiseLevels"] = vExpertiseLevels;

    /* PROCEDURES */
    const procNewPosition = getOrCreateProcedure({ id: "procNewPosition", procedureName: "astp_Resource_CreatePosition" });

    /* GENERIC DATASOURCES */
    const dsResourceOrgUnitLkp = getOrCreateDataObject({
        id: 'dsResourceOrgUnitLkp' + "_" + crypto.randomUUID(),
        viewName: "aviw_Resource_OrgUnits",
        maxRecords: 100,
        loadRecents: true,
        selectFirstRowOnLoad: false,
        fields: [{ name: "ID" }, { name: "NameAndTitle", sortOrder: 1 }, { name: "UnitType" }, { name: "Parent" }],
    });

    const dsFunctionRoleLkp = getOrCreateDataObject({
        id: 'dsFunctionRoleLkp' + "_" + crypto.randomUUID(),
        viewName: "aviw_Resource_FunctionRoles",
        maxRecords: 25,
        loadRecents: true,
        selectFirstRowOnLoad: false,
        whereClause: "Closed IS NULL",
        fields: [{ name: "ID" }, { name: "Name", sortOrder: 1 }, { name: "Closed" }],
    });

    const dsRateCategoryLkp = getOrCreateDataObject({
        id: 'dsRateCategoryLkp' + "_" + crypto.randomUUID(),
        viewName: "aviw_Resource_RateCategoriesOrgUnitsExpanded",
        maxRecords: 25,
        loadRecents: true,
        selectFirstRowOnLoad: false,
        fields: [{ name: "Currency" }, { name: "IsDefault", sortOrder: 1 }, { name: "Location" }, { name: "OrgUnit_ID" }, { name: "OrgUnitOrigin" }, { name: "RateCategory", sortOrder: 2 }, { name: "RateCategory_ID" }, { name: "RateCategoryRate" }],
    });

    const dsWBSLkp = getOrCreateDataObject({
        id: 'dsWBSLkp' + "_" + crypto.randomUUID(),
        viewName: "aviw_Complete_WBS",
        maxRecords: 25,
        loadRecents: true,
        selectFirstRowOnLoad: false,
        masterDataObject_ID: null,
        masterDetailDefinition: [],
        fields: [{ name: "ID" }, { name: "Name" }, { name: "Title" }, { name: "NamePath", sortOrder: 1}, { name: "Level" }, { name: "ExcludeFromResourceMgmt" }, { name: "NameAndTitle" }, { name: "OrgUnit" }]
    });

    /* WATCH */
    watch(() => vProcData.value.OrgUnit_ID, (vNew, vOld) => {
        vRateCategoryFilter.value = "OrgUnit_ID = " + vNew;
        vWBSFilter.value = "IsWorkpackage=1 AND Canceled=0 AND IsClosed=0 AND ExcludeFromResourceMgmt=0 AND OrgUnit_ID = " + vNew;
    });

    /* FUNCTIONS */
    function refreshDataObject() {
        if(props.RefreshDataObject) {
            props.RefreshDataObject.load();
        }
    };

    const createPosition = async () => {
    isConfirmationModalShown.value = true;
    let vExpertiseArr = [];
    let vExpertiseWithLevelArr = [];
    toRaw(vExpertise.value).forEach(function(x) { vExpertiseArr.push([x.Expertise_ID]); });
    toRaw(vExpertiseLevels.value).forEach(function(x) { vExpertiseWithLevelArr.push([ x.Expertise_ID, x.ExpertiseLevel_ID ? x.ExpertiseLevel_ID : -1, x.CertificationLevel_ID ? x.CertificationLevel_ID : -1 ]); });
    let vData = await procNewPosition.execute({ 
        OrgUnit_ID: vProcData.value.OrgUnit_ID,
        Description: vProcData.value.Description,
        WBS_ID: vProcData.value.WBS_ID,
        FromDate: vProcData.value.FromDate,
        ToDate: vProcData.value.ToDate,
        FTE: vProcData.value.FTE,
        NumberOfAssignments: vProcData.value.NumberOfAssignments,
        FunctionRole_ID: vProcData.value.FunctionRole_ID,
        RateCategory_ID: vProcData.value.RateCategory_ID,
        Responsible_ID: vProcData.value.Responsible_ID,
        ResourceOrgUnit_ID: vProcData.value.ResourceOrgUnit_ID,
        Expertise: vExpertiseArr.length > 0 ? vExpertiseArr : undefined,
        ExpertiseWithLevel: vExpertiseWithLevelArr.length > 0 ? vExpertiseWithLevelArr : undefined
    });

    if(vData.Table.length > 0) {
        
        confirm({
            title: $t("Project Position"),
            message: $t("New Project Position has been created"),
            buttons: [
                { text: $t("Close"), class: "btn-primary", action: (rej) => { rej(2); }},
                { text: $t("Create More Positions"), class: "btn-primary", action: (res) => { res(0); }},
                { text: $t("Open Position"), class: "btn-dark", action: (res) => { res(1); }}
            ]
        }).then((result) => {
            switch (result.CustomAction) {
                case 0:
                    isConfirmationModalShown.value = false;
                    resetNewPositionModal();
                    break;
                case 1:
                    window.location.href = "/resource-position?ID=" + vData.Table[0].ID;
                    break;
                case 2:
                    isConfirmationModalShown.value = false; 
                    resetNewPositionModal();
                    newPositionModal.value?.hide();
                    break;
            }
        }).catch((error) => {
            console.log(error)
        });
    } else {
        isConfirmationModalShown.value = false; 
    }
}

const resetNewPositionModal = () => {
    vProcData.value = {
        OrgUnit_ID: context.id,
        ResourceOrgUnit_ID: null,
        FunctionRole_ID: null,
        Responsible_ID: null,
        FromDate: null,
        ToDate: null,
        FTE: 1,
        NumberOfAssignments: 1,
        RateCategory_ID: null,
        WBS_ID: null,
        Description: null,
        ExpertiseIDs: null,
        ExpertiseWithLevelIDs: null,
        OrgUnit: context.name,
        ResourceOrgUnit: null,
        FunctionRole: null,
        Responsible: null,
        RateCategory: null,
        WBS: null,
        Expertise: null,
        ExpertiseWithLevel: null
    };
}

context.on('Change', (ctx) => {
    vProcData.value.OrgUnit_ID = ctx.id;
    vProcData.value.OrgUnit = ctx.name;
})
</script>
<template>
    <OModal ref="newPositionModal" :title="$t('New Project Position')" dialogClass="modal-xl" @hidden="refreshDataObject">
        <div class="modal-content">
            <!-- Body --> 
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-6">
                        <label title="$t('OrgUnit')">{{ $t("OrgUnit") }} *</label>
                        <OOrgunitsLookup allow-clear :bind="sel => { vProcData.OrgUnit_ID = sel.ID, vProcData.OrgUnit = sel.OrgUnit }" required>
                            <template #orgunit>
                                <input class="form-control form-control-sm" :title="$t('Org Unit')" readonly :value="vProcData.OrgUnit" />
                            </template>
                        </OOrgunitsLookup>
                    </div>
                    <div class="form-group col-6">
                        <label title="$t('Resource OrgUnit')">{{ $t("Resource OrgUnit") }} *</label>
                        <ODataLookup class="form-control form-control-sm" :title="$t('Resource OrgUnit')" readonly allow-clear required text-input :data-object="dsResourceOrgUnitLkp"
                            :bind="sel => { vProcData.ResourceOrgUnit_ID = sel.ID, vProcData.ResourceOrgUnit = sel.NameAndTitle }" v-model="vProcData.ResourceOrgUnit">
                            <OColumn field="NameAndTitle" width="400" :header-title="$t('Name')" :header-name="$t('Name')" />
                            <OColumn field="UnitType" width="200" :header-title="$t('Unit Type')" :header-name="$t('Unit Type')" />
                            <OColumn field="Parent" width="400" :header-title="$t('Parent')" :header-name="$t('Parent')" />
                        </ODataLookup>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-6">
                        <label title="$t('Function Role')">{{ $t("Function Role") }} *</label>
                        <ODataLookup class="form-control form-control-sm" :title="$t('Function Role')" :data-object="dsFunctionRoleLkp" required
                            :value="vProcData.FunctionRole" :bind="sel => { vProcData.FunctionRole_ID = sel.ID; vProcData.FunctionRole = sel.Name }">
                            <OColumn field="ID" width="80" :header-title="$t('ID')" :header-name="$t('ID')" />
                            <OColumn field="Name" width="300" :header-title="$t('Name')" :header-name="$t('Name')" />
                        </ODataLookup>
                    </div>
                    <div class="form-group col-6">
                        <label title="$t('Responsible')">{{ $t("Responsible") }} *</label>
                        <OPersonsLookup class="form-control form-control-sm" :bind="sel => { vProcData.Responsible_ID = sel.ID; vProcData.Responsible = sel.Name; }" v-model="vProcData.Responsible" required/>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-6">
                        <label title="$t('From')">{{ $t("From") }} *</label>
                        <ODatePicker class="form-control form-control-sm" v-model="vProcData.FromDate" format="Short Date" required/>
                    </div>

                    <div class="form-group col-6">
                        <label title="$t('To')">{{ $t("To") }} *</label>
                        <ODatePicker class="form-control form-control-sm" v-model="vProcData.ToDate" format="Short Date" required/>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-6">
                        <label title="$t('Full Time Equivalent')">{{ $t("Full Time Equivalent") }} *</label>
                        <o-number-editor class="form-control form-control-sm" :title="$t('Full Time Equivalent')" v-model="vProcData.FTE" format="%" required/>
                    </div>

                    <div class="form-group col-6">
                        <label>{{ $t("Number of Assignments") }} *</label>
                        <o-number-editor class="form-control form-control-sm" :title="$t('Number of Assignments')" v-model="vProcData.NumberOfAssignments" format="1 234" required/>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-6">
                        <label title="$t('Rate Category')">{{ $t("Rate Category") }}</label>
                        <ODataLookup class="form-control form-control-sm" :title="$t('RateCategory')" :data-object="dsRateCategoryLkp" :whereClause="vRateCategoryFilter" reloadOnWhereClauseChange
                            :value="vProcData.RateCategory" :bind="sel => { vProcData.RateCategory_ID = sel.RateCategory_ID; vProcData.RateCategory = sel.RateCategory }">
                            <OColumn name="RateCategory" :header-name="$t('Category')" width="300" />
                            <OColumn name="RateCategoryRate" :header-name="$t('Rate')" width="100" />
                            <OColumn name="Currency" :header-name="$t('Currency')" width="100" />
                            <OColumn name="OrgUnitOrigin" :header-name="$t('Org Unit')" width="300" />
                            <OColumn name="Location" :header-name="$t('Location')" width="300" />
                        </ODataLookup>
                    </div>

                    <div class="form-group col-6">
                        <label title="$t('WBS')">{{ $t("WBS") }}</label>
                        <ODataLookup class="form-control form-control-sm" :title="$t('WBS')" :data-object="dsWBSLkp" :whereClause="vWBSFilter" reloadOnWhereClauseChange
                            :value="vProcData.WBS" :bind="sel => { vProcData.WBS_ID = sel.ID; vProcData.WBS = sel.Name }">
                            <OColumn name="OrgUnit" :header-name="$t('OrgUnit')" width="200" />
                            <OColumn name="ID" :header-name="$t('ID')" width="80" />
                            <OColumn name="Name" :header-name="$t('Name')" width="100" />
                            <OColumn name="Title" :header-name="$t('Title')" width="200" />
                            <OColumn name="NamePath" :header-name="$t('NamePath')" width="400" />
                        </ODataLookup>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12">
                        <label title="$t('Description')">{{ $t("Description") }}</label>
                        <textarea class="form-control" v-model="vProcData.Description"></textarea>
                    </div>
                </div>

                <div class="row mt-1">
                    <div class="form-group col-12">
                        <ModalExpertise name="modal-expertise" :OrgUnitIdPath="context.idPath" @expertise-added="(n) => { vExpertise = n; console.log('expertise-added'); }" @expertise-levels-added="(n) => { vExpertiseLevels = n; console.log('expertise-levels-added') }"/> 
                        <button class="btn btn-sm btn-secondary" v-target:modal-expertise>{{ $t("Add / Remove Expertise") }}</button> 
                        <div v-for="vRow in vExpertise">
                            {{ vRow.Expertise }}
                        </div>
                        <div v-for="vRow in vExpertiseLevels">
                            {{ vRow.Expertise }}<span v-if="vRow.ExpertiseLevel_ID"> ({{ vRow.ExpertiseLevel }})</span><span v-if="vRow.CertificationLevel_ID"> <span>{</span>{{ vRow.CertificationLevel }}<span>}</span></span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-primary" @click="createPosition" :disabled="isConfirmationModalShown">{{ $t("Create Position") }}</button>
            </div>
        </div>
    </OModal>
</template>